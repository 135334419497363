import React from 'react';

const LTV = () => {
  return (
    <div>
      <div className="takeaways">
        <h2>Key Takeaways</h2>
        <ul>
          <li>Loan-to-value is the mortgage loan expressed as a percentage of the property value. If the mortgage is £75,000 and the property value is £100,000 the loan-to-value is 75%</li>
          <li>The higher the loan-to-value the higher the mortgage rates you’ll have access to as you’ll be borrowing more</li>
          <li>When remortgaging, how much of your mortgage loan you’ve already paid and if your home has risen in value will also be taken into account</li>
          <li>Before remortgaging it’s a good idea to see if you can make a lump sum overpayment to get access to better deals</li>
        </ul>
      </div>
      <div className="transcript">
        <h2>Transcript</h2>
        <p>
          What is LTV and why does it matter?<br/><br/>
          LTV or loan-to-value is the mortgage expressed as a percentage of the property value. For example, if the mortgage amount that you wanted to borrow was 75,000 pounds and the property was worth £100,000, then the loan-to-value would be 75%.<br /><br/>
          Why is this important? Loan-to-value is one of the most important factors in the mortgage process. Generally speaking, the higher the loan-to-value, the higher the interest rate for the mortgage.<br /><br/>
          It might also mean higher fees as a higher borrowing amount represents a greater risk than a mortgage lender.<br /><br/>
          All lenders will set a maximum loan-to-value that they will lend up to protect themselves against property value decreases in the future as they cannot be above 100% loan-to-value, as this would mean you're in what's called negative equity.<br /><br/>
          How does LTV affect your remortgaging?<br /><br/>
          As when you first buy your home, loan-to-value will affect the choice and quality of mortgage options available to you. However, this time there are additional factors involved. For example, how much of your mortgage have you already paid off? And how much has your home risen in value?<br /><br/>
          If you've been paying off your mortgage for a while and your home has also gone up in value, the loan-to-value will now be lower.<br /><br/>
          Better deals may now be available with lower interest rates. Remortgaging, therefore tends to be easier in a rising property market and less so if prices are falling.<br /><br/>
          If you're approaching your time to remortgage, it's worth taking a look to see if you're able to pay down any lump sums on your mortgage or put down a larger deposit to improve your loan-to-value and get access to better deals.<br /><br/>
        </p>
      </div>
    </div>
  );
};

export default LTV;
